import React from "react";
import { graphql } from "gatsby";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import Layout from "../components/Layout";
import Slider from "react-slick";
import SEO from "../components/SEO";

const AdminPortal = ({ data }) => {

    var customSettingsCrucialAP = {
        dots: false,
        speed: 500,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1367,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 1279,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    function increaseImageWidthCoveringCrucialAspectsMSTeams() {
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .covering-crucial-aspects-ms-teams .covering-crucial-aspects-ms-teams-left').classList.toggle('covering-crucial-aspects-ms-teams-left-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .covering-crucial-aspects-ms-teams .covering-crucial-aspects-ms-teams-right').classList.toggle('covering-crucial-aspects-ms-teams-right-active');

        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .system-alerts-back-end .system-alerts-back-end-left').classList.remove('system-alerts-back-end-left-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .system-alerts-back-end .system-alerts-back-end-right').classList.remove('system-alerts-back-end-right-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .gathering-information-quick-glance .gathering-information-quick-glance-left').classList.remove('gathering-information-quick-glance-left-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .gathering-information-quick-glance .gathering-information-quick-glance-right').classList.remove('gathering-information-quick-glance-right-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .tools-system-maintenance .tools-system-maintenance-left').classList.remove('tools-system-maintenance-left-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .tools-system-maintenance .tools-system-maintenance-right').classList.remove('tools-system-maintenance-right-active');
    }

    function increaseImageWidthSystemAlertsBE() {
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .system-alerts-back-end .system-alerts-back-end-left').classList.toggle('system-alerts-back-end-left-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .system-alerts-back-end .system-alerts-back-end-right').classList.toggle('system-alerts-back-end-right-active');

        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .covering-crucial-aspects-ms-teams .covering-crucial-aspects-ms-teams-left').classList.remove('covering-crucial-aspects-ms-teams-left-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .covering-crucial-aspects-ms-teams .covering-crucial-aspects-ms-teams-right').classList.remove('covering-crucial-aspects-ms-teams-right-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .gathering-information-quick-glance .gathering-information-quick-glance-left').classList.remove('gathering-information-quick-glance-left-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .gathering-information-quick-glance .gathering-information-quick-glance-right').classList.remove('gathering-information-quick-glance-right-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .tools-system-maintenance .tools-system-maintenance-left').classList.remove('tools-system-maintenance-left-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .tools-system-maintenance .tools-system-maintenance-right').classList.remove('tools-system-maintenance-right-active');
    }

    function increaseImageWidthGatheringInformationQuickGlance() {
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .gathering-information-quick-glance .gathering-information-quick-glance-left').classList.toggle('gathering-information-quick-glance-left-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .gathering-information-quick-glance .gathering-information-quick-glance-right').classList.toggle('gathering-information-quick-glance-right-active');

        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .covering-crucial-aspects-ms-teams .covering-crucial-aspects-ms-teams-left').classList.remove('covering-crucial-aspects-ms-teams-left-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .covering-crucial-aspects-ms-teams .covering-crucial-aspects-ms-teams-right').classList.remove('covering-crucial-aspects-ms-teams-right-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .system-alerts-back-end .system-alerts-back-end-left').classList.remove('system-alerts-back-end-left-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .system-alerts-back-end .system-alerts-back-end-right').classList.remove('system-alerts-back-end-right-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .tools-system-maintenance .tools-system-maintenance-left').classList.remove('tools-system-maintenance-left-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .tools-system-maintenance .tools-system-maintenance-right').classList.remove('tools-system-maintenance-right-active');
    }

    function increaseImageWidthToolsSystemMaintenance() {
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .tools-system-maintenance .tools-system-maintenance-left').classList.toggle('tools-system-maintenance-left-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .tools-system-maintenance .tools-system-maintenance-right').classList.toggle('tools-system-maintenance-right-active');

        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .covering-crucial-aspects-ms-teams .covering-crucial-aspects-ms-teams-left').classList.remove('covering-crucial-aspects-ms-teams-left-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .covering-crucial-aspects-ms-teams .covering-crucial-aspects-ms-teams-right').classList.remove('covering-crucial-aspects-ms-teams-right-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .system-alerts-back-end .system-alerts-back-end-left').classList.remove('system-alerts-back-end-left-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .system-alerts-back-end .system-alerts-back-end-right').classList.remove('system-alerts-back-end-right-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .gathering-information-quick-glance .gathering-information-quick-glance-left').classList.remove('gathering-information-quick-glance-left-active');
        document.querySelector('.app-section-admin-portal-manage-eproducts-capture .gathering-information-quick-glance .gathering-information-quick-glance-right').classList.remove('gathering-information-quick-glance-right-active');
    }

    const introduction = data.introduction.nodes;
    const introduction_vi = data.introduction_vi.nodes;
    const covering_crucial_aspects_ms_teams = data.covering_crucial_aspects_ms_teams.nodes;
    const covering_crucial_aspects_ms_teams_vi = data.covering_crucial_aspects_ms_teams_vi.nodes;
    const system_alerts_back_end = data.system_alerts_back_end.nodes;
    const system_alerts_back_end_vi = data.system_alerts_back_end_vi.nodes;
    const gathering_information_quick_glance = data.gathering_information_quick_glance.nodes;
    const gathering_information_quick_glance_vi = data.gathering_information_quick_glance_vi.nodes;
    const tools_system_maintenance = data.tools_system_maintenance.nodes;
    const tools_system_maintenance_vi = data.tools_system_maintenance_vi.nodes;
    const crucial_to_be_covered_manage = data.crucial_to_be_covered_manage.nodes;
    const crucial_to_be_covered_manage_vi = data.crucial_to_be_covered_manage_vi.nodes;
    const crucial_to_be_covered_report = data.crucial_to_be_covered_report.nodes;
    const crucial_to_be_covered_report_vi = data.crucial_to_be_covered_report_vi.nodes;
    const crucial_to_be_covered_etask = data.crucial_to_be_covered_etask.nodes;
    const crucial_to_be_covered_etask_vi = data.crucial_to_be_covered_etask_vi.nodes;
    const crucial_to_be_covered_ehelpdesk = data.crucial_to_be_covered_ehelpdesk.nodes;
    const crucial_to_be_covered_ehelpdesk_vi = data.crucial_to_be_covered_ehelpdesk_vi.nodes;
    const features_manage_workflow = data.features_manage_workflow.nodes;
    const features_manage_workflow_vi = data.features_manage_workflow_vi.nodes;
    const features_system_notification = data.features_system_notification.nodes;
    const features_system_notification_vi = data.features_system_notification_vi.nodes;

    return (
        <Layout>
            <SEO title="eAdmin Portal" description="Manage eProducts for IT admin, maintain the system performance to improve stability. Manage projects, files in Teams, generate reports, and permission in eProducts. " meta={[{ name: `keywords`, content: ["admin tool", "eProducts management", "eProducts system maintaince"] }]} pathname="/adminportal" />
            <div className="main-content-padding">
                <div className="app-general-seciton app-admin-portal-introduction" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <div className="app-admin-portal-introduction-detail">
                            <Row className="app-admin-portal-introduction-row-custom">
                                <Col className="app-admin-portal-introduction-col-custom" xs={12} md={12} lg={5}>
                                    <div className="app-admin-portal-introduction-left">
                                        {introduction.map((res) => (
                                            <div className="app-data-en" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                        {introduction_vi.map((res) => (
                                            <div className="app-data-vi" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                        ))}
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={1}></Col>
                                <Col className="app-admin-portal-introduction-col-custom" xs={12} md={12} lg={6}>
                                    <div className="app-admin-portal-introduction-right">
                                        <img className="app-admin-portal-introduction-img" src="/app-admin-portal-introduction.png" alt="admin portal introduction" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <div className="app-general-seciton app-section-admin-portal-manage-eproducts-capture" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <Row>
                            <Col xs={12} md={12} lg={2}></Col>
                            <Col xs={12} md={12} lg={8}>
                                <div className="app-title">
                                    <h2 className="app-data-en">Manage Appvity eProducts in a single view for quickly capture</h2>
                                    <h2 className="app-data-vi">Manage Appvity eProducts in a single view for quickly capture</h2>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={2}></Col>
                        </Row>
                        <div className="app-admin-portal-manage-eproducts-capture-detail">
                            <Row className="app-admin-portal-manage-eproducts-capture-detail-row-custom covering-crucial-aspects-ms-teams">
                                <Col xs={12} md={12} lg={12} xl={1}></Col>
                                <Col className="app-admin-portal-manage-eproducts-capture-detail-col-custom covering-crucial-aspects-ms-teams-left" xs={12} md={12} lg={12} xl={5}>
                                    {covering_crucial_aspects_ms_teams.map((res) => (
                                        <div className="app-data-en app-manage-eproducts-capture-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                    ))}
                                    {covering_crucial_aspects_ms_teams_vi.map((res) => (
                                        <div className="app-data-vi app-manage-eproducts-capture-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                    ))}
                                </Col>
                                <Col className="app-admin-portal-manage-eproducts-capture-detail-col-custom covering-crucial-aspects-ms-teams-right" xs={12} md={12} lg={12} xl={5}>
                                    <div className="app-manage-eproducts-capture-right-side">
                                        <img 
                                            className="app-admin-portal-manage-eproducts-capture-covering-crucial-aspects-ms-teams" 
                                            src="/app-admin-portal-manage-eproducts-capture-covering-crucial-aspects-ms-teams.svg" 
                                            alt="app admin portal manage eproducts capture covering crucial aspects ms teams" 
                                            onClick={increaseImageWidthCoveringCrucialAspectsMSTeams} 
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={12} xl={1}></Col>
                            </Row>
                            <Row className="app-admin-portal-manage-eproducts-capture-detail-row-custom system-alerts-back-end">
                                <Col xs={12} md={12} lg={12} xl={1}></Col>
                                <Col className="app-admin-portal-manage-eproducts-capture-detail-col-custom system-alerts-back-end-left" xs={12} md={12} lg={12} xl={5}>
                                    <div className="app-manage-eproducts-capture-left-side">
                                        <img 
                                            className="app-admin-portal-manage-eproducts-capture-system-alerts-back-end" 
                                            src="/app-admin-portal-manage-eproducts-capture-system-alerts-back-end.svg" 
                                            alt="app admin portal manage eproducts capture system alerts back end" 
                                            onClick={increaseImageWidthSystemAlertsBE} 
                                        />
                                    </div>
                                </Col>
                                <Col className="app-admin-portal-manage-eproducts-capture-detail-col-custom system-alerts-back-end-right" xs={12} md={12} lg={12} xl={5}>
                                    {system_alerts_back_end.map((res) => (
                                        <div className="app-data-en app-manage-eproducts-capture-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                    ))}
                                    {system_alerts_back_end_vi.map((res) => (
                                        <div className="app-data-vi app-manage-eproducts-capture-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                    ))}
                                </Col>
                                <Col xs={12} md={12} lg={12} xl={1}></Col>
                            </Row>
                            <Row className="app-admin-portal-manage-eproducts-capture-detail-row-custom gathering-information-quick-glance">
                                <Col xs={12} md={12} lg={12} xl={1}></Col>
                                <Col className="app-admin-portal-manage-eproducts-capture-detail-col-custom gathering-information-quick-glance-left" xs={12} md={12} lg={12} xl={5}>
                                    {gathering_information_quick_glance.map((res) => (
                                        <div className="app-data-en app-manage-eproducts-capture-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                    ))}
                                    {gathering_information_quick_glance_vi.map((res) => (
                                        <div className="app-data-vi app-manage-eproducts-capture-left-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                    ))}
                                </Col>
                                <Col className="app-admin-portal-manage-eproducts-capture-detail-col-custom gathering-information-quick-glance-right" xs={12} md={12} lg={12} xl={5}>
                                    <div className="app-manage-eproducts-capture-right-side">
                                        <img 
                                            className="app-admin-portal-manage-eproducts-capture-gathering-information-quick-glance" 
                                            src="/app-admin-portal-manage-eproducts-capture-gathering-information-quick-glance.svg" 
                                            alt="app admin portal manage eproducts capture gathering information quick glance" 
                                            onClick={increaseImageWidthGatheringInformationQuickGlance} 
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={12} xl={1}></Col>
                            </Row>
                            {/* <Row className="app-admin-portal-manage-eproducts-capture-detail-row-custom tools-system-maintenance">
                                <Col xs={12} md={12} lg={12} xl={1}></Col>
                                <Col className="app-admin-portal-manage-eproducts-capture-detail-col-custom tools-system-maintenance-left" xs={12} md={12} lg={12} xl={5}>
                                    <div className="app-manage-eproducts-capture-left-side">
                                        <img 
                                            className="app-admin-portal-manage-eproducts-capture-tools-system-maintenance" 
                                            src="/app-admin-portal-manage-eproducts-capture-tools-system-maintenance.svg" 
                                            alt="app admin portal manage-eproducts-capture tools system maintenance" 
                                            onClick={increaseImageWidthToolsSystemMaintenance} 
                                        />
                                    </div>
                                </Col>
                                <Col className="app-admin-portal-manage-eproducts-capture-detail-col-custom tools-system-maintenance-right" xs={12} md={12} lg={12} xl={5}>
                                    {tools_system_maintenance.map((res) => (
                                        <div className="app-data-en app-manage-eproducts-capture-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                    ))}
                                    {tools_system_maintenance_vi.map((res) => (
                                        <div className="app-data-vi app-manage-eproducts-capture-right-side" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id}/>
                                    ))}
                                </Col>
                                <Col xs={12} md={12} lg={12} xl={1}></Col>
                            </Row> */}
                        </div>
                    </Container>
                </div>
                <div className="app-general-seciton app-section-admin-portal-crucial-to-be-covered" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <Row>
                            <Col xs={12} md={12} lg={2}></Col>
                            <Col xs={12} md={12} lg={8}>
                                <div className="app-title">
                                    <h2 className="app-data-en">Crucial sections to be covered in eAdmin Portal</h2>
                                    <h2 className="app-data-vi">Crucial sections to be covered in eAdmin Portal</h2>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={2}></Col>
                        </Row>
                        <div className="app-admin-portal-crucial-to-be-covered-detail">
                            <div className="app-admin-portal-crucial-tabs app-data-en">
                                <Tabs defaultActiveKey="Manage">
                                    <Tab className="app-data-en" eventKey="Manage" title="Manage">
                                        <Slider {...customSettingsCrucialAP}>
                                            {crucial_to_be_covered_manage.map((res) => (
                                                <div className="app-crucial-slider" key={res.id}>
                                                    <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                    <div className="content">
                                                        <h5>{res.frontmatter.title}</h5>
                                                        <h6>{res.frontmatter.description}</h6>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </Tab>
                                    <Tab className="app-data-en" eventKey="report" title="Report">
                                        <Slider {...customSettingsCrucialAP}>
                                            {crucial_to_be_covered_report.map((res) => (
                                                <div className="app-crucial-slider" key={res.id}>
                                                    <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                    <div className="content">
                                                        <h5>{res.frontmatter.title}</h5>
                                                        <h6>{res.frontmatter.description}</h6>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </Tab>
                                    {/* <Tab className="app-data-en" eventKey="etask" title="eTask">
                                        <Slider {...customSettingsCrucialAP}>
                                            {crucial_to_be_covered_etask.map((res) => (
                                                <div className="app-crucial-slider" key={res.id}>
                                                    <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                    <div className="content">
                                                        <h5>{res.frontmatter.title}</h5>
                                                        <h6>{res.frontmatter.description}</h6>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </Tab>
                                    <Tab className="app-data-en" eventKey="ehelpdesk" title="eHelpdesk">
                                        <Slider {...customSettingsCrucialAP}>
                                            {crucial_to_be_covered_ehelpdesk.map((res) => (
                                                <div className="app-crucial-slider" key={res.id}>
                                                    <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                    <div className="content">
                                                        <h5>{res.frontmatter.title}</h5>
                                                        <h6>{res.frontmatter.description}</h6>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </Tab> */}
                                </Tabs>
                            </div>
                            <div className="app-admin-portal-crucial-tabs app-data-vi">
                                <Tabs defaultActiveKey="Manage">
                                    <Tab className="app-data-vi" eventKey="Manage" title="Manage">
                                        <Slider {...customSettingsCrucialAP}>
                                            {crucial_to_be_covered_manage_vi.map((res) => (
                                                <div className="app-crucial-slider" key={res.id}>
                                                    <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                    <div className="content">
                                                        <h5>{res.frontmatter.title}</h5>
                                                        <h6>{res.frontmatter.description}</h6>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </Tab>
                                    <Tab className="app-data-vi" eventKey="report" title="Report">
                                        <Slider {...customSettingsCrucialAP}>
                                            {crucial_to_be_covered_report_vi.map((res) => (
                                                <div className="app-crucial-slider" key={res.id}>
                                                    <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                    <div className="content">
                                                        <h5>{res.frontmatter.title}</h5>
                                                        <h6>{res.frontmatter.description}</h6>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </Tab>
                                    {/* <Tab className="app-data-vi" eventKey="etask" title="eTask">
                                        <Slider {...customSettingsCrucialAP}>
                                            {crucial_to_be_covered_etask_vi.map((res) => (
                                                <div className="app-crucial-slider" key={res.id}>
                                                    <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                    <div className="content">
                                                        <h5>{res.frontmatter.title}</h5>
                                                        <h6>{res.frontmatter.description}</h6>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </Tab>
                                    <Tab className="app-data-vi" eventKey="ehelpdesk" title="eHelpdesk">
                                        <Slider {...customSettingsCrucialAP}>
                                            {crucial_to_be_covered_ehelpdesk_vi.map((res) => (
                                                <div className="app-crucial-slider" key={res.id}>
                                                    <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                                    <div className="content">
                                                        <h5>{res.frontmatter.title}</h5>
                                                        <h6>{res.frontmatter.description}</h6>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </Tab> */}
                                </Tabs>
                            </div>
                        </div>
                    </Container>
                </div>
                <div className="app-general-seciton app-section-admin-portal-manage-workflow-system-notification" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <div className="app-admin-portal-manage-workflow-system-notification-detail">
                            <Row>
                                {features_manage_workflow.map((res) => (
                                    <Col className="app-data-en app-admin-portal-manage-workflow-system-notification-detail-col-custom" xs={12} md={12} lg={6} key={res.id}>
                                        <div className="app-admin-portal-manage-workflow-detail">
                                            <h2>{res.frontmatter.title}</h2>
                                            <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                        </div>
                                    </Col>
                                ))}
                                {features_manage_workflow_vi.map((res) => (
                                    <Col className="app-data-vi app-admin-portal-manage-workflow-system-notification-detail-col-custom" xs={12} md={12} lg={6} key={res.id}>
                                        <div className="app-admin-portal-manage-workflow-detail">
                                            <h2>{res.frontmatter.title}</h2>
                                            <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                        </div>
                                    </Col>
                                ))}
                                {features_system_notification.map((res) => (
                                    <Col className="app-data-en app-admin-portal-manage-workflow-system-notification-detail-col-custom" xs={12} md={12} lg={6} key={res.id}>
                                        <div className="app-admin-portal-system-notification-detail">
                                            <h2>{res.frontmatter.title}</h2>
                                            <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                        </div>
                                    </Col>
                                ))}
                                {features_system_notification_vi.map((res) => (
                                    <Col className="app-data-vi app-admin-portal-manage-workflow-system-notification-detail-col-custom" xs={12} md={12} lg={6} key={res.id}>
                                        <div className="app-admin-portal-system-notification-detail">
                                            <h2>{res.frontmatter.title}</h2>
                                            <div dangerouslySetInnerHTML={{ __html: res.html }} />
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Container>
                </div>
            </div>
        </Layout>
    );
};

export default AdminPortal;

export const query = graphql`
    query AdminPortalPage {
        introduction: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "admin_portal_introduction" } } }
        ) {
            nodes {
                html
                id
            }
        }
        introduction_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "admin_portal_introduction_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        covering_crucial_aspects_ms_teams: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "admin_portal_covering_crucial_aspects_ms_teams" } } }
        ) {
            nodes {
                html
                id
            }
        }
        covering_crucial_aspects_ms_teams_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "admin_portal_covering_crucial_aspects_ms_teams_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        system_alerts_back_end: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "admin_portal_system_alerts_back_end" } } }
        ) {
            nodes {
                html
                id
            }
        }
        system_alerts_back_end_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "admin_portal_system_alerts_back_end_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        gathering_information_quick_glance: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "admin_portal_gathering_information_quick_glance" } } }
        ) {
            nodes {
                html
                id
            }
        }
        gathering_information_quick_glance_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "admin_portal_gathering_information_quick_glance_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        tools_system_maintenance: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "admin_portal_tools_system_maintenance" } } }
        ) {
            nodes {
                html
                id
            }
        }
        tools_system_maintenance_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "admin_portal_tools_system_maintenance_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        crucial_to_be_covered_manage: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "admin_portal_crucial_to_be_covered_manage" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    description
                }
                id
            }
        }
        crucial_to_be_covered_manage_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "admin_portal_crucial_to_be_covered_manage_vi" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    description
                }
                id
            }
        }
        crucial_to_be_covered_report: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "admin_portal_crucial_to_be_covered_report" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    description
                }
                id
            }
        }
        crucial_to_be_covered_report_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "admin_portal_crucial_to_be_covered_report_vi" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    description
                }
                id
            }
        }
        crucial_to_be_covered_etask: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "admin_portal_crucial_to_be_covered_etask" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    description
                }
                id
            }
        }
        crucial_to_be_covered_etask_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "admin_portal_crucial_to_be_covered_etask_vi" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    description
                }
                id
            }
        }
        crucial_to_be_covered_ehelpdesk: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "admin_portal_crucial_to_be_covered_ehelpdesk" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    description
                }
                id
            }
        }
        crucial_to_be_covered_ehelpdesk_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "admin_portal_crucial_to_be_covered_ehelpdesk_vi" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    description
                }
                id
            }
        }
        features_manage_workflow: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "admin_portal_features_manage_workflow" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
        features_manage_workflow_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "admin_portal_features_manage_workflow_vi" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
        features_system_notification: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "admin_portal_features_system_notification" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
        features_system_notification_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "admin_portal_features_system_notification_vi" } } }
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
                id
            }
        }
    }
`;

